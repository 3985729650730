import {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import axios from 'axios';
import {
  useAccount,
  useContractRead,
  erc20ABI,
  useNetwork,
  useSwitchNetwork,
} from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import useGlobalContext from '../hooks/useGlobalContext';

import { Trans, useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner'

// components
import Deposits from './dashboard/Deposits';
import AccruedYield from './dashboard/AccruedYield';
import SavedGas from './dashboard/SavedGas';
import FAQ from './dashboard/FAQ';

const DashboardPage = () => {
  const [interestRate, setInterestRate] = useState<number>(0);
  const [currentInterestRate, setCurrentInterestRate] = useState<number>(0);
  const [pendingDeposits, setPendingDeposits] = useState<Deposit[]>([]);
  const [pendingWithdrawals, setPendingWithdrawals] = useState<Withdrawal[]>([]);
  const [gasSavedOnDeposits, setGasSavedOnDeposits] = useState<number>(0);
  const [gasSavedOnWithdrawals, setGasSavedOnWithdrawals] = useState<number>(0);

  // NEW
  const [userDepositsAPI, setUserDepositsAPI] = useState<string>('0.00');
  const [dsfLpBalance, setDsfLpBalance] = useState<string>('0.000000000000000000');
  const [ratioUserAPI, setRatioUserAPI] = useState<string>('0.0000000000000000');
  const [availableToWithdrawAPI, setAvailableToWithdrawAPI] = useState<string>('0.000000');
  const [availableToWithdrawUSDTAPI, setAvailableToWithdrawUSDTAPI] = useState<number>(0);
  const [cvxShareAPI, setCvxShareAPI] = useState<string>('0.000000000000000000');
  const [cvxCostAPI, setCvxCostAPI] = useState<string>('0.000000');
  const [crvShareAPI, setCrvShareAPI] = useState<string>('0.000000000000000000');
  const [crvCostAPI, setCrvCostAPI] = useState<string>('0.000000');
  const [userAnnualYieldRate, setUserAnnualYieldRate] = useState<string>('0.00');
  const [isRequestOk, setIsRequestOk] = useState<boolean>(false);

  const { address } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { open } = useWeb3Modal()
  const { t } = useTranslation('deposit');

  const {
    managedInDSF,
    userDeposits,
    withdrawableIncome,
  } = useGlobalContext();  

  const getPendingTransactions = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.dsf.finance/deposit/pending/${address}`);

      return response.data;
    } catch (error) {
      console.error('Something went wrong');
    }

    return {
      deposits: [],
      withdrawals: [],
    };
  }, [address]);

  const getGasSaved = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.dsf.finance/deposit/gas-saved/${address}`);

      return response.data;
    } catch (error) {
      console.error('Something went wrong');
    }
    return {
      deposits: 0,
      withdrawals: 0,
    }
  }, [address]);


    const fetchData = useCallback(async () => {
      if(!address) return;
        const fetchWithRetry = async (retries = 7, delay = 1000) => {
            try { 
                const response = await axios.get(`https://api2.dsf.finance/request/walletinfo?wallet=${address}&apikey=a5d8b4f2-6e3a-49b1-8c5e-3d7b2a4c6f1e`);
                if(response.status === 200){setIsRequestOk(true)}
                console.log(response);
                const data = response.data;
                console.log("API request:",address, data);
                setDsfLpBalance(data.dsf_lp_balance);
                setRatioUserAPI(data.ratio_user);
                setAvailableToWithdrawAPI(data.available_to_withdraw);
                setAvailableToWithdrawUSDTAPI(data.available_to_withdraw - data.user_deposits);
                setCvxShareAPI(data.cvx_share);
                setCvxCostAPI(data.cvx_cost);
                setCrvShareAPI(data.crv_share);
                setCrvCostAPI(data.crv_cost);
                setUserAnnualYieldRate(data.annual_yield_rate);
                setInterestRate(Math.round(parseFloat(data.apy_365) * 100) / 100);
                setCurrentInterestRate(Math.round(parseFloat(data.apy_today) * 100) / 100);

                if (data.user_deposits == null || isNaN(data.user_deposits)) {
                  console.log('Invalid user_deposits value:', data.user_deposits);
                  throw new Error("Invalid user_deposits value", data.user_deposits);
                }
                setUserDepositsAPI(data.user_deposits);
            } catch (error) {
                console.error('Something went wrong while fetching API data: ', error);
                if (retries > 0) {
                    console.log(`Retrying... (${retries} retries left)`);
                    setTimeout(() => fetchWithRetry(retries - 1, delay), delay);
                } else {
                    console.error('Failed to fetch data after multiple attempts:', error);
                }
            }
        };
    
        await fetchWithRetry();
    }, [address]);

  useEffect(() => {
    getPendingTransactions().then((transactions) => {
      const { deposits, withdrawals } = transactions;

      setPendingDeposits(deposits);
      setPendingWithdrawals(withdrawals);
    });
  }, [getPendingTransactions]);

  useEffect(() => {
    getGasSaved().then((gasSaved) => {
      const { deposits, withdrawals } = gasSaved;

      setGasSavedOnDeposits(deposits);
      setGasSavedOnWithdrawals(withdrawals);
    });
  }, [getGasSaved]);


  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // NEW
  useEffect(() => {
    console.log('----------------------------------------------');
    console.log('managedInDSF', managedInDSF);
    console.log('----------------------------------------------');
    console.log('userDeposits:', userDeposits, userDepositsAPI);
    console.log('currentInterestRate:', currentInterestRate);
    console.log('interestRate:', interestRate);
    console.log('pendingDeposits:', pendingDeposits);
    console.log('pendingWithdrawals:', pendingWithdrawals);
    console.log('withdrawableIncome:', withdrawableIncome, availableToWithdrawUSDTAPI);
    console.log('----------------------------------------------');
    console.log('Ratio User:', ratioUserAPI);
    console.log('CVX Share:', cvxShareAPI);
    console.log('CRV Share:', crvShareAPI);
    console.log('CVX Cost:', cvxCostAPI);
    console.log('CRV Cost:', crvCostAPI);
  }, [
    ratioUserAPI,
    cvxShareAPI,
    crvShareAPI,
    cvxCostAPI,
    crvCostAPI,
    userDeposits,
    currentInterestRate,
    interestRate,
    pendingDeposits,
    pendingWithdrawals,
    withdrawableIncome,
    availableToWithdrawAPI,
    availableToWithdrawUSDTAPI,
    userDepositsAPI,
    managedInDSF
  ]);

  function renderConnectWallet() {
    if(!address && window.innerWidth >800) {
      return(
        <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-50 flex items-center justify-center">
        <div className="bg-white w-[670px] h-[400px] rounded-3xl shadow-lg flex flex-col items-center justify-center px-[110px]">
        <div className="text-[#3956FE] text-[22px] font-bold text-center">
        {t('please_connect_wallet')}
        </div>
        <button className='flex items-center gap-2 bg-[#3956FE] text-white px-4 py-2 rounded-xl mt-[50px] px-[30px] active:opacity-75' onClick={open}>
          <img src='/metamask.png' alt="wallet" />
          {t('connect_wallet')}
          </button>
          <div className="text-[#979797] text-[16px] font-bold text-center mt-[50px]">
        {t('connect_wallet_description')}
        </div>
        </div>
      </div>
      )
    } else if(!address && window.innerWidth <= 800) {
      return(
        <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-50 flex items-center justify-center">
        <div className="bg-white w-[95%] h-[80vh] rounded-3xl shadow-lg flex flex-col items-center justify-center px-[50px]">
        <div className="text-[#3956FE] text-[22px] font-bold text-center">
        {t('please_connect_wallet')}
        </div>
        <button className='flex items-center gap-2 bg-[#3956FE] text-white px-4 py-2 rounded-xl mt-[50px] px-[30px] active:opacity-75' onClick={open}>
          <img src='/metamask.png' alt="wallet" />
          {t('connect_wallet')}
          </button>
          <div className="text-[#979797] text-[16px] font-bold text-center mt-[50px]">
        {t('connect_wallet_description')}
        </div>
        </div>
      </div>
      )
    }
  }


function renderLoader() {
  if(!isRequestOk && window.innerWidth > 800){
    return(
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-30 flex items-center justify-center">
      <div className="bg-white w-[670px] h-[150px] rounded-3xl shadow-lg flex flex-row items-center justify-center px-[60px]">
      <RotatingLines
        strokeColor="#3956FE"
        strokeWidth="5"
        animationDuration="0.75"
        width="70"
        visible={true}
      />
      <div className="text-[#3956FE] text-[22px] font-bold text-center ml-[20px]">
      {t('loader_content')}
      </div>
      </div>
    </div>
    )
  } else if(!isRequestOk && window.innerWidth <= 800){
    return(
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-50 flex items-center justify-center">
      <div className="bg-white w-[95%] h-[150px] rounded-3xl shadow-lg flex flex-row items-center justify-center px-[30px]">
      <RotatingLines
        strokeColor="#3956FE"
        strokeWidth="5"
        animationDuration="0.75"
        width="70"
        visible={true}
      />
      <div className="text-[#3956FE] text-[22px] font-bold text-center ml-[20px]">
      {t('loader_content')}
      </div>
      </div>
    </div>
    )
  }
}
  return (
    <div className="flex flex-col tablet:flex-row gap-8 tablet:gap-10 text-[14px] max-h-[calc(100vh-68px-2.5rem)] overflow-y-scroll">
      {renderConnectWallet()}
      {renderLoader()}
      <section className="tablet:w-3/5 tablet:sticky top-0 left-0">
        <Deposits
          depositAmount={userDepositsAPI}
          currentInterestRate={currentInterestRate}
          interestRate={interestRate}
          pendingDeposits={pendingDeposits}
          pendingWithdrawals={pendingWithdrawals}
          availableToWithdraw={availableToWithdrawUSDTAPI} 
          userAnnualYieldRate={userAnnualYieldRate}
        />
      </section>
      <div className="flex flex-col gap-10 tablet:w-full">
        <section>
          <AccruedYield
            cvxAmount={parseFloat(cvxShareAPI)}
            crvAmount={parseFloat(crvShareAPI)}
            cvxAmountInUsd={parseFloat(cvxCostAPI)}
            crvAmountInUsd={parseFloat(crvCostAPI)}
            availableToWithdraw={availableToWithdrawUSDTAPI}
            loading={managedInDSF > 0 && !userDeposits}
          />
        </section>
        <section>
          <SavedGas
            deposits={gasSavedOnDeposits}
            withdrawals={gasSavedOnWithdrawals}
          />
        </section>
        <section>
          <FAQ />
        </section>
      </div>
    </div>
  );
};

export default DashboardPage;
