import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import useWithdrawalContext from '../../../hooks/useWithdrawalContext';
import useGlobalContext from '../../../hooks/useGlobalContext';
import {
  useAccount,
  useBalance,
  useContractWrite,
  usePrepareContractWrite,
} from 'wagmi';
import dsfAddresses from '../../../utils/dsf_addresses.json';
import dsfAbi from '../../../utils/dsf_abi.json';
import { ethers } from 'ethers';
import { useTranslation } from 'react-i18next';

// icons
import { ReactComponent as ETHIcon } from '../../../assets/images/currency/ETH.svg';

// components
import StepsContainer from '../../deposit/steps/StepsContainer';
import CurrencyIcon from '../../../components/CurrencyIcon';
import Button from '../../../components/CustomButton';
import SuccessWithdrawal from '../../../modals/SuccessWithdrawal';

const ThirdStep = () => {
  const {
    selectedCurrency,
    amountToWithdraw,
    optimized,
    optimizedGasPrice,
    gasPrice,
    resetContext,
    amountToWithdrawInPercentage,
  } = useWithdrawalContext();
  const {
    ETHPrice,
    dsfLpBalance,
  } = useGlobalContext();
  const { t } = useTranslation('withdraw');
  const navigate = useNavigate();
  const { address } = useAccount();
  const { data: ethBalance } = useBalance({
    address,
  });
  const [successModalOpened, setSuccessModalOpened] = useState(false);

  const withdrawalAmounts = useMemo(() => {
    const amounts = [0, 0, 0];
    // toFixed(5) to avoid issues with min amount to withdraw coz of slippage
    const roundedAmount  = (+amountToWithdraw).toFixed(5);
    const parsedAmount = +ethers.parseUnits(roundedAmount, 6).toString();

    switch (selectedCurrency) {
      case 'DAI':
        amounts[0] = parsedAmount;
        break;
      case 'USDC':
        amounts[1] = parsedAmount;
        break;
      case 'USDT':
        amounts[2] = parsedAmount;
        break;
      default:
        break;
    }

    return amounts;
  }, [amountToWithdraw, selectedCurrency]);

  const dsfLPAmountToWithdraw = useMemo(() => {
    if (!amountToWithdrawInPercentage || !dsfLpBalance) return 0;

    return BigInt(Math.floor(Number(dsfLpBalance) * amountToWithdrawInPercentage));
  }, [dsfLpBalance, amountToWithdrawInPercentage]);

  const selectedCurrencyIndex = useMemo(() => {
    switch (selectedCurrency) {
      case 'DAI':
        return 0;
      case 'USDC':
        return 1;
      case 'USDT':
        return 2;
      default:
        return 0;
    }
  }, [selectedCurrency]);

  const { config, isSuccess: prepareSuccess, error } = usePrepareContractWrite({
    address: dsfAddresses.DSF as `0x${string}`,
    abi: dsfAbi,
    functionName: optimized ? 'feesOptimizationWithdrawal' : 'withdraw',
    // withdrawal type === 1 coz another is not realized yet
    args: optimized
      // ? [dsfLPAmountToWithdraw, withdrawalAmounts]
      ? [dsfLPAmountToWithdraw, [0,0,0]]
      : [dsfLPAmountToWithdraw, [0,0,0], 1, selectedCurrencyIndex],
  });
  const {
    data: withdrawalResult,
    isLoading,
    isSuccess,
    write: withdraw,
  } = useContractWrite(config);

  const estimatedFeeETH = useMemo(() => {
    if (!amountToWithdraw || !selectedCurrency || !ETHPrice) return 0;

    if (optimized) {
      return +(optimizedGasPrice / ETHPrice).toFixed(4);
    }

    return +(gasPrice / ETHPrice).toFixed(4);
  } ,[
    amountToWithdraw, optimizedGasPrice,
    gasPrice, ETHPrice, selectedCurrency,
    optimized
  ]);

  const withdrawalHash = useMemo(() => {
    if (!withdrawalResult) return '';

    return withdrawalResult.hash;
  }, [withdrawalResult]);

  const disabledWithdrawalButton = useMemo(() => {
    if (!ethBalance) return true;

    return isLoading || !prepareSuccess || +ethBalance.formatted < estimatedFeeETH;
  }, [isLoading, prepareSuccess, ethBalance, estimatedFeeETH]);

  const withdrawResult = useMemo(() => {
    return (Number(amountToWithdraw) * (1 - 0.00098)).toFixed(2);
  }, [amountToWithdraw]);

  const closeSuccessModal = useCallback(() => {
    setSuccessModalOpened(false);
    navigate('/transactions');
    resetContext();
  }, [setSuccessModalOpened, resetContext, navigate]);

  const withdrawButtonClickHandler = useCallback(() => {
    withdraw?.();
  }, [withdraw]);

  useEffect(() => {
    if (isSuccess && !isLoading) {
      setSuccessModalOpened(true);
    }
  }, [isSuccess, isLoading]);

  if (!selectedCurrency) {
    return null;
  }

  return (
    <StepsContainer title={t('you_get')}>
      <div className="tablet:flex items-end gap-5">
        <label className="block mt-6 text-sm tablet:w-1/2">
          {t('you_withdraw')}
          <div className="flex items-center justify-between rounded-xl border border-gray px-4 py-3 mt-1 text-base">
            <div className="flex items-center">
              <CurrencyIcon currency={selectedCurrency}/>
              <span className="ml-[10px] text-gray-900 font-medium">{`${amountToWithdraw} ${selectedCurrency}`}</span>
            </div>
            <span>
              $ {(+amountToWithdraw).toLocaleString('en-US')}
        </span>
          </div>
        </label>
        <label className="block mt-5 tablet:w-1/2">
          <span className="text-sm">
            {t('fee')}
          </span>
          <div className="flex items-center justify-between bg-gray rounded-xl px-4 py-3 mt-1">
            <span>${(optimized ? optimizedGasPrice : gasPrice).toFixed(2)}</span>
            <div className="flex items-center gap-3">
              <div className="flex justify-center items-center bg-gray rounded-full w-6 h-6">
                <ETHIcon/>
              </div>
              {estimatedFeeETH} ETH
            </div>
          </div>
        </label>
        <label className="block mt-5 tablet:w-1/2">
          <span className="text-sm">
            {t('result_slippage')}
          </span>
          <div className="flex items-center gap-3 bg-gray rounded-xl px-4 py-3 mt-1">
            <CurrencyIcon currency={selectedCurrency}/>
            {withdrawResult} {selectedCurrency}
          </div>
        </label>
      </div>
      <div
        className={`
          ${!ethBalance || +ethBalance.formatted < estimatedFeeETH ? 'block' : 'hidden'} font-light text-red-500
        `}
      >
        {
          t(
            'not_enough_ETH',
            {amount: +((estimatedFeeETH - +(ethBalance?.formatted || 0)) * 1.15).toFixed(7), ns: 'deposit'}
          )
        }
      </div>
      <div className="mt-5 tablet:mt-6">
        <Button
          variant="filled"
          onClick={withdrawButtonClickHandler}
          disabled={disabledWithdrawalButton}
        >
          {t('withdraw')}
        </Button>
      </div>
      <SuccessWithdrawal
        opened={successModalOpened}
        onClose={closeSuccessModal}
        etherscanLink={`https://etherscan.io/tx/${withdrawalHash}`}
      />
    </StepsContainer>
  )
};

export default ThirdStep;
